import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        redirect:'/website/home'
    },
    {
        path: '/website',
        name: 'website',
        component: () => import('@/views/website/main'),
        children: [
            {
                path: 'home',
                name: 'home',
                component: () => import('@/views/website/home/home'),
            },
            {
                path: 'personal',
                name: 'personal',
                component: () => import('@/views/website/personalInfo/personalInfo'),
            }
        ]
    }
]

const router = createRouter({
    mode: "history",
    history: createWebHashHistory(),
    routes
})

export default router
